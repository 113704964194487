<template>
  <section id="WorkExperienceAndSalary">
    <v-row class="my-0" id="skeleton-loading" no-gutters justify="center" v-if="isFetchingItems">
      <v-col cols="12">
        <v-skeleton-loader type="text@5"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="actions mt-4 d-flex justify-end">
        <v-skeleton-loader type="button"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-form
      v-else
      id="employeeWorkExperienceAndSalaryForm"
      ref="employeeWorkExperienceAndSalaryForm"
      @submit.prevent="validateForm"
      v-model="employeeWorkExperienceAndSalaryFormValid"
      lazy-validation
    >
      <div class="form-body border-all pa-4 ma-4 rounded">
        <v-row class="my-0">
          <v-col md="4">
            <v-text-field
              hide-details="auto"
              filled
              dense
              label="Əvvəlki iş stajı*"
              v-model="form.totalPastWorkExperience"
              hint="gün/ay/il"
              v-mask="'##/##/##'"
              :rules="rules.totalPastWorkExperience"
              persistent-hint
            />
          </v-col>
          <v-col md="4">
            <v-menu
              v-model="hireDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hireDateFormatted"
                  label="Başlama tarixi*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  filled
                  dense
                  :rules="rules.hireDate"
                ></v-text-field>
              </template>
              <v-date-picker
                :month-format="getMonthFormat"
                :header-date-format="getHeaderDateFormat"
                :title-date-format="getTitleDateFormat"
                :weekday-format="getWeekdayFormat"
                :first-day-of-week="1"
                v-model="hireDate"
                @input="hireDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4">
            <v-text-field
              label="Ümumi iş stajı"
              disabled
              filled
              dense
              v-model="form.totalWorkExperience"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="number"
              hide-details="auto"
              filled
              dense
              label="Əmək haqqı gross(AZN)"
              v-model="form.grossSalary"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="number"
              hide-details="auto"
              filled
              dense
              label="Əmək Haqqı net(AZN)"
              v-model="form.netSalary"
            />
          </v-col>
        </v-row>
      </div>
      <div class="form-footer px-4 pb-4">
        <div class="d-flex justify-end">
          <v-btn
            color="success"
            depressed
            form="employeeWorkExperienceAndSalaryForm"
            type="submit"
            :loading="loading"
          >
            Yadda saxla
          </v-btn>
        </div>
      </div>
    </v-form>
  </section>
</template>

<script>
import datePickerFormatterMixin from '@/mixins/datePickerFormatter'
import API from '@/services'
import bus from "../../../../../../event-bus";

export default {
  mixins: [datePickerFormatterMixin],
  data() {
    return {
      employeeWorkExperienceAndSalaryFormValid: false,
      loading: false,
      isFetchingItems: false,
      hireDateMenu: false,
      hireDate: null,
      form: {
        totalPastWorkExperience: null,
        hireDate: null,
        totalWorkExperience: null,
        grossSalary: null,
        netSalary: null
      },
      rules: {
        totalPastWorkExperience: [
          v => {
            if (!v) return 'Əvvəlki iş stajı daxil olunmalıdır'

            if (v.length < 8) return 'Əvvəlki iş stajı "dd/MM/yy" formatında olmalıdır'

            const [days, motnhs] = v.split('/')

            if (days > 29) return 'Gün düzgün formada daxil olunmalıdır'

            if (motnhs > 11) return 'Ay düzgün formada daxil olunmalıdır'

            return true
          }
        ],
        hireDate: [v => !!v || 'İşə başlama tarixi daxil olunmalıdır']
      }
    }
  },
  computed: {
    hireDateFormatted() {
      return this.formatDate(this.hireDate, 'string')
    }
  },
  created() {
    this.fetchHREmployeeIternshipAndSalary()
  },
  methods: {
    fetchHREmployeeIternshipAndSalary() {
      this.isFetchingItems = true
      const { id } = this.$route.params

      API.fetchHREmployeeIternshipAndSalary(id)
        .then(response => {
          this.form = response
          this.hireDate = this.form.hireDate
        })
        .catch(() => {
          bus.$emit('notificationMessage', {
            color: 'error',
            text: 'Staj/Əmək haqqı məlumatlarını əldə etmək mümkün olmadı.'
          })
        })
        .finally(() => {
          this.isFetchingItems = false
        })
    },
    validateForm: async function() {
      const isValid = this.$refs.employeeWorkExperienceAndSalaryForm.validate()

      if (isValid && this.employeeWorkExperienceAndSalaryFormValid) await this.submitForm()
    },
    async submitForm() {
      this.loading = true
      try {
        const formData = {
          ...this.form,
          hireDate: this.hireDate,
          employeeId: parseInt(this.$route.params.id)
        }

        await API.updateHREmployeeIternshipAndSalary(formData)

        this.fetchHREmployeeIternshipAndSalary()

        bus.$emit('notificationMessage', {
          color: 'success',
          text: 'Staj/Əmək haqqı məlumatları əlavə edildi.'
        })
      } catch (_) {
        bus.$emit('notificationMessage', {
          color: 'error',
          text: 'Staj/Əmək haqqı məlumatlarını əlavə etmək mümkün olmadı.'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
#skeleton-loading {
  margin: 16px !important;

  .col:not(.actions) {
    padding: 16px 8px !important;
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 4px;

    .v-skeleton-loader__text.v-skeleton-loader__bone {
      display: inline-block;
      width: 30%;
      margin: 0 8px;
    }
  }
}
</style>
